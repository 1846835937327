<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'Whatsapp'" :subtitle="'Detalhes'" />
    <div class="page-content container-fluid">
      <properties-card />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import PropertiesCard from '@/components/whatsappj/PropertiesCard.vue';

export default {
  name: 'WhatsAppjDetail',
  components: {
    PageHeader,
    PropertiesCard,
  },
  data() {
    return {};
  },
};
</script>
